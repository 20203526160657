import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApiUrlInterceptor} from './interceptors/api-url.interceptor';
import {BgImagePipe} from './pipes/bg-image.pipe';
import {FileImageUrlPipe} from './pipes/file-image-url.pipe';
import {HtmlContentPipe} from './pipes/html-content.pipe';
import {SaveUrlPipe} from './pipes/save-url.pipe';
import {ToOptionsPipe} from './pipes/to-options.pipe';
import {SaveUrlFilePipe} from './pipes/save-url-file.pipe';
import {TodayPipe} from './pipes/today.pipe';
import {DirPipe} from './pipes/dir.pipe';
import {NullToEmptyStringPipe} from './pipes/null-to-empty-string.pipe';
import {UrlFromImagePipe} from './pipes/url-from-image.pipe';
import {ErrorInterceptor} from "./interceptors/error.interceptor";
import {UrlFromImageBgPipe} from './pipes/url-from-image-bg.pipe';

@NgModule({
    declarations: [
        BgImagePipe,
        FileImageUrlPipe,
        HtmlContentPipe,
        SaveUrlPipe,
        ToOptionsPipe,
        SaveUrlFilePipe,
        TodayPipe,
        DirPipe,
        NullToEmptyStringPipe,
        UrlFromImagePipe,
        UrlFromImageBgPipe,
    ],
    imports: [CommonModule, HttpClientModule],
    providers: [
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: ApiUrlInterceptor,
        //     multi: true,
        // },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: ErrorInterceptor,
        //     multi: true,
        // },
    ],
    exports: [
        BgImagePipe,
        HtmlContentPipe,
        SaveUrlPipe,
        ToOptionsPipe,
        FileImageUrlPipe,
        SaveUrlFilePipe,
        TodayPipe,
        DirPipe,
        NullToEmptyStringPipe,
        UrlFromImagePipe,
        UrlFromImageBgPipe
    ]
})
export class CoreModule {
}
