import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {CountryModel} from "./models/country.model";
import {TitleModel} from "./models/title.model";
import {SectorModel} from "./models/sector.model";
import {InvestorTypeModel} from "./models/investor-type.model";
import {DesignationModel} from "./models/designation.model";
import {NoOfEmployeesModel} from "./models/no-of-employees.model";
import {DevelopmentStageModel} from "./models/development-stage.model";
import {FundingTypeModel} from "./models/funding-type.model";
import {CurrencyModel} from "./models/currency.model";
import {ApiService} from "../api.service";
import {SortPipe} from "../../utils/pipes/sort.pipe";
import {default as apiLinks} from "src/app/utils/constants/api-links.json";
import {IndustrialClassificationLevelTwoModel} from "./models/industrial-classification-level-two.model";
import {BusinessTypeModel} from "./models/business-type.model";
import {FieldOfStudyModel} from "./models/field-of-study.model";
import {YearOfGraduationModel} from "./models/year-of-graduation.model";
import {YearOfExperienceModel} from "./models/year-of-experience.model";
import {IndustryOfExpertiseModel} from "./models/industry-of-expertise.model";
import {ExpertiseModel} from "./models/expertise.model";
import {ReasonToApplyModel} from "./models/reason-to-apply.model";
import {BehaviorSubject, forkJoin, Observable, of} from "rxjs";
import enLang from '../../../assets/i18n/en.json'
import arLang from '../../../assets/i18n/ar.json'
import {tap} from "rxjs/operators";
import {PreferredHourlyRateModel} from "./models/preferred-hourly-rate.model";
import {ProjectsPerWeekModel} from "./models/projects-per-week.model";
import {ShareOrderTypesModel} from "./models/share-order-types.model";
import {SharesDealTypeModel} from "./models/shares-deal-type.model";
import {FintechCategoryModel} from "./models/fintech-category.model";
import {CityModel} from "./models/city.model";
import {SharesOfferStatusModel} from "./models/shares-offer-status.model";
import {ShareTypesModel} from "./models/share-types.model";
import {MenaCountryModel} from "./models/mena-country.model";
import {LegalFormModel} from "./models/legal-form.model";
import {IndustrialClassificationLevelOneModel} from "./models/industrial-classification-level-one.model";
import {IndustrialClassificationLevelFourModel} from "./models/industrial-classification-level-four.model";
import { TranslateService } from "@ngx-translate/core";
import { LanguageHandlerService } from "../language/language-handler.service";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  initialisationCompleted = new BehaviorSubject<boolean>(false);

  private businessTypeList!: BusinessTypeModel[];
  private countryModelList!: CountryModel[];
  private currencyModelList!: CurrencyModel[];
  private designationModelList!: DesignationModel[];
  private developmentStageModelList!: DevelopmentStageModel[];
  private customTitleList!: TitleModel[];
  private fundingTypeModelList!: FundingTypeModel[];
  private industrialClassificationLevelOneModelList!: IndustrialClassificationLevelOneModel[];
  private industrialClassificationLevelTwoModelList!: IndustrialClassificationLevelTwoModel[];
  private industrialClassificationLevelFourModelList!: IndustrialClassificationLevelFourModel[];
  private investorTypeModelList!: InvestorTypeModel[];
  private noOfEmployeesModelList!: NoOfEmployeesModel[];
  private preferredHourlyRateModelList!: PreferredHourlyRateModel[];
  private projectsPerWeekModelList!: ProjectsPerWeekModel[];
  private sectorModelList!: SectorModel[];
  private titleModelList!: TitleModel[];
  private fieldOfStudyModelList!: FieldOfStudyModel[];
  private yearOfGraduationModelList!: YearOfGraduationModel[];
  private yearOfExperienceModelList!: YearOfExperienceModel[];
  private industryOfExpertiseModelList!: IndustryOfExpertiseModel[];
  private expertiseModelList!: ExpertiseModel[];
  private reasonToApplyModelList!: ReasonToApplyModel[];
  private orderTypesModelList!: ShareOrderTypesModel[];
  private sharesDealTypeModel!: SharesDealTypeModel[];
  private fintechCategoryModelList!: FintechCategoryModel[];
  private cityList!: CityModel[];
  private sharesOfferStatus!: SharesOfferStatusModel[];
  private sharesOfferTradeType!: any;
  private shareTypes!: ShareTypesModel[];
  private menaCountryModelList!: MenaCountryModel[];
  private saudiCountryModelList!: MenaCountryModel[];
  private legalFormModelList!: LegalFormModel[];

  constructor(
    private apiService: ApiService,
    private sort: SortPipe, @Inject(PLATFORM_ID) private platformId: Object,
    public langHandler: LanguageHandlerService, private langTranslator: TranslateService
  ) {
  }

  getAllBusinessTypes(): BusinessTypeModel[] {
    return this.businessTypeList;
  }

  getAllCountries(): CountryModel[] {
    return this.countryModelList;
  }

  getAllCurrencies(): CurrencyModel[] {
    return this.currencyModelList;
  }

  getAllDesignations(): DesignationModel[] {
    return this.designationModelList;
  }

  getAllDevelopmentStages(): DevelopmentStageModel[] {
    return this.developmentStageModelList;
  }

  getCustomTitleList(): TitleModel[] {
    return this.customTitleList;
  }

  getAllFundingTypes(): FundingTypeModel[] {
    return this.fundingTypeModelList;
  }

  getAllIndustrialClassificationsLevelOne(): IndustrialClassificationLevelOneModel[] {
    return this.industrialClassificationLevelOneModelList;
  }

  getAllIndustrialClassificationsLevelTwo(): IndustrialClassificationLevelTwoModel[] {
    return this.industrialClassificationLevelTwoModelList;
  }

  getAllIndustrialClassificationsLevelFour(): IndustrialClassificationLevelFourModel[] {
    return this.industrialClassificationLevelFourModelList;
  }

  getAllInvestorTypes(): InvestorTypeModel[] {
    return this.investorTypeModelList;
  }

  getAllNoOfEmployees(): NoOfEmployeesModel[] {
    return this.noOfEmployeesModelList;
  }

  getAllPreferredHourlyRates(): PreferredHourlyRateModel[] {
    return this.preferredHourlyRateModelList;
  }

  getAllProjectsPerWeeks(): ProjectsPerWeekModel[] {
    return this.projectsPerWeekModelList;
  }

  getAllSectors(): SectorModel[] {
    return this.sectorModelList;
  }

  getAllTitles(): TitleModel[] {
    return this.titleModelList;
  }

  getAllFieldOfStudies(): FieldOfStudyModel[] {
    return this.fieldOfStudyModelList;
  }

  getAllYearOfGraduations(): YearOfGraduationModel[] {
    return this.yearOfGraduationModelList;
  }

  getAllYearOfExperiences(): YearOfExperienceModel[] {
    return this.yearOfExperienceModelList;
  }

  getAllIndustryOfExpertise(): IndustryOfExpertiseModel[] {
    return this.industryOfExpertiseModelList;
  }

  getAllExpertise(): ExpertiseModel[] {
    return this.expertiseModelList;
  }

  getAllReasonsToApply(): ExpertiseModel[] {
    return this.reasonToApplyModelList;
  }

  getAllOrderTypes(): ShareOrderTypesModel[] {
    return this.orderTypesModelList;
  }

  getAllFintechCategories(): FintechCategoryModel[] {
    return this.fintechCategoryModelList;
  }

  getAllCities(): CityModel[] {
    return this.cityList;
  }

  getAllSharesDealType(): SharesDealTypeModel[] {
    return this.sharesDealTypeModel;
  }

  getAllSharesOfferStatus(): SharesOfferStatusModel[] {
    return this.sharesOfferStatus;
  }

  getAllSharesOfferTradeTypes() {
    return this.sharesOfferTradeType;
  }
  getAllShareTypes(): ShareTypesModel[] {
    return this.shareTypes;
  }

  getMenaCountries(): MenaCountryModel[] {
    return this.menaCountryModelList;
  }
  getSaudiCountry(): MenaCountryModel[] {
    return this.saudiCountryModelList;
  }

  getAllLegalForms(): LegalFormModel[] {
    return this.legalFormModelList;
  }
  // Preload this data on application start
  async preloadLookupData(): Promise<void> {
    return await new Promise<void>((resolve) => {
      this.populateCountries();
      this.populateIndustrialClassificationsLevelTwo();
      this.populateDevelopmentStages();
      this.populateBusinessTypes();
      this.populateCurrencies();
      this.populateDesignations();
      this.populateFundingTypes();
      this.populateInvestorTypes();
      this.populateNoOfEmployees();
      this.populateSectors();
      this.populateTitles();
      this.populateFieldOfStudies();
      this.populateYearOfGraduations();
      this.populateYearOfExperiences();
      this.populateIndustryOfExpertise();
      this.populateExpertise();
      this.populateReasonsToApply();
      this.populatePreferredHourlyRates();
      this.populateProjectsPerWeeks();
      resolve();
    });
  }

  loadInitialStyles(): Observable<any>{

    if (isPlatformBrowser(this.platformId)) {
      // Logic to set language based on hostname (executed only on browser)
      const _defLang = window.location.hostname.includes('en') ? 'en' : 'ar';
      localStorage.setItem('defaultLanguage', _defLang);
      const storedLang = localStorage.getItem('defaultLanguage');
      const defLang = storedLang?.trim() || this.langHandler.arLangCode;

      localStorage.setItem('defaultLanguage', defLang);
      this.langHandler.loadStyle(`lang-${defLang}.css`, defLang);
      this.langTranslator.setDefaultLang(defLang);
      this.langTranslator.setTranslation(defLang, defLang === 'en' ? enLang : arLang);

      return of(null)
    }

    return of(null)
  }


  preloadInitLookupData(): Observable<any> {
    return forkJoin<any>([
      this.apiService.get(apiLinks.api.mainService.lookup.getAllBusinessTypeList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getCountriesList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getCurrenciesList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getDesignationsList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getDevelopmentStagesList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getFundingTypesList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getAllIndustrialClassificationsLevelOneList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getInvestorTypesList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getNoOfEmployeesList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getSectorsList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getTitlesList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getFieldOfStudiesList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getYearOfGraduationsList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getYearOfExperiencesList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getIndustryOfExpertiseList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getExpertiseList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getReasonToApplyList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getAllPreferredHourlyRateList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getAllProjectsPerWeekList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getCustomTitlesList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getOrderTypesList, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getAllFintechCategories, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getAllCities, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getAllSharesDealType, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getAllSharesOfferStatus, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getAllSharesOfferTradeTypes, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getAllShareTypes, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getMenaCountries, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getSaudiCountry, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getAllLegalForm, null),
      this.apiService.get(apiLinks.api.mainService.lookup.getAllIndustrialClassificationsLevelFourList, null),
    ]).pipe(
      tap((response: any) => {
        this.businessTypeList = this.sort.transform(response[0].data, 'asc', 'businessTypeName');
        this.countryModelList = this.sort.transform(response[1], 'asc', 'countryName');
        this.currencyModelList = response[2];
        this.designationModelList = this.sort.transform(response[3].data, 'asc', 'designationName');
        this.developmentStageModelList = this.sort.transform(response[4].data, 'asc', 'developmentStageName');
        this.fundingTypeModelList = this.sort.transform(response[5].data, 'asc', 'fundingTypeName');
        this.industrialClassificationLevelOneModelList = this.sort.transform(response[6].data, 'asc', 'classificationName');
        this.investorTypeModelList = this.sort.transform(response[7].data, 'asc', 'investorTypeName');
        this.noOfEmployeesModelList = this.sort.transform(response[8].data, 'asc', 'id');
        this.sectorModelList = this.sort.transform(response[9].data, 'asc', 'sectorName');
        this.titleModelList = this.sort.transform(response[10].data, 'asc', 'titleName');
        this.fieldOfStudyModelList = this.sort.transform(response[11].data, 'asc', 'fieldOfStudyName');
        this.yearOfGraduationModelList = response[12].data;
        this.yearOfExperienceModelList = response[13].data;
        this.industryOfExpertiseModelList = this.sort.transform(response[14].data, 'asc', 'industryOfExperitise');
        this.expertiseModelList = this.sort.transform(response[15].data, 'asc', 'areaOfExpertise');
        this.reasonToApplyModelList = this.sort.transform(response[16].data, 'asc', 'reasonToApply');
        this.preferredHourlyRateModelList = response[17].data;
        this.projectsPerWeekModelList = response[18].data;
        this.customTitleList = response[19].data;
        this.orderTypesModelList = response[20].data;
        this.fintechCategoryModelList = response[21].data;
        this.cityList = this.sort.transform(response[22].data, 'asc', 'cityName');
        this.sharesDealTypeModel = response[23].data;
        this.sharesOfferStatus = response[24].data;
        this.sharesOfferTradeType = response[25].data;
        this.shareTypes = response[26].data;
        this.menaCountryModelList = response[27];
        this.saudiCountryModelList = response[28];
        this.legalFormModelList = response[29].data;
        this.industrialClassificationLevelFourModelList = this.sort.transform(response[30].data, 'asc', 'classificationName');

      })
    );
  }

  //Private method to populate lookup data
  private populateBusinessTypes() {
    this.apiService.get(apiLinks.api.mainService.lookup.getAllBusinessTypeList, null).subscribe((result) => {
      this.businessTypeList = this.sort.transform(result.data, 'asc', 'businessTypeName');
    });
  }

  private populateCustomTitleList() {
    this.apiService.get(apiLinks.api.mainService.lookup.getCustomTitlesList, null).subscribe((result) => {
      this.customTitleList = this.sort.transform(result.data, 'asc', 'titleName');
    });
  }

  private populateCountries() {
    this.apiService.get(apiLinks.api.mainService.lookup.getCountriesList, null).subscribe((result) => {
      this.countryModelList = this.sort.transform(result, 'asc', 'countryName');
    });
  }

  private populateCurrencies() {
    this.apiService.get(apiLinks.api.mainService.lookup.getCurrenciesList, null).subscribe((result) => {
      this.currencyModelList = result;
    });
  }

  private populateDesignations() {
    this.apiService.get(apiLinks.api.mainService.lookup.getDesignationsList, null).subscribe((result) => {
      this.designationModelList = this.sort.transform(result.data, 'asc', 'designationName');
    });
  }

  private populateDevelopmentStages() {
    this.apiService.get(apiLinks.api.mainService.lookup.getDevelopmentStagesList, null).subscribe((result) => {
      this.developmentStageModelList = this.sort.transform(result.data, 'asc', 'developmentStageName');
    });
  }

  private populateFundingTypes() {
    this.apiService.get(apiLinks.api.mainService.lookup.getFundingTypesList, null).subscribe((result) => {
      this.fundingTypeModelList = this.sort.transform(result.data, 'asc', 'fundingTypeName');
    });
  }

  private populateIndustrialClassificationsLevelTwo() {
    this.apiService.get(apiLinks.api.mainService.lookup.getAllIndustrialClassificationsLevelTwoList, null).subscribe((result) => {
      this.industrialClassificationLevelTwoModelList = this.sort.transform(result.data, 'asc', 'classificationName');
    });
  }

  private populateInvestorTypes() {
    this.apiService.get(apiLinks.api.mainService.lookup.getInvestorTypesList, null).subscribe((result) => {
      this.investorTypeModelList = this.sort.transform(result.data, 'asc', 'investorTypeName');
    });
  }

  private populateNoOfEmployees() {
    this.apiService.get(apiLinks.api.mainService.lookup.getNoOfEmployeesList, null).subscribe((result) => {
      this.noOfEmployeesModelList = this.sort.transform(result.data, 'asc', 'id');
    });
  }

  private populateSectors() {
    this.apiService.get(apiLinks.api.mainService.lookup.getSectorsList, null).subscribe((result) => {
      this.sectorModelList = this.sort.transform(result.data, 'asc', 'sectorName');
    });
  }

  private populateTitles() {
    this.apiService.get(apiLinks.api.mainService.lookup.getTitlesList, null).subscribe((result) => {
      this.titleModelList = this.sort.transform(result.data, 'asc', 'titleName');
    });
  }

  private populateFieldOfStudies() {
    this.apiService.get(apiLinks.api.mainService.lookup.getFieldOfStudiesList, null).subscribe((result) => {
      this.fieldOfStudyModelList = this.sort.transform(result.data, 'asc', 'fieldOfStudyName');
    });
  }

  private populateYearOfGraduations() {
    this.apiService.get(apiLinks.api.mainService.lookup.getYearOfGraduationsList, null).subscribe((result) => {
      this.yearOfGraduationModelList = result.data;
    });
  }

  private populateYearOfExperiences() {
    this.apiService.get(apiLinks.api.mainService.lookup.getYearOfExperiencesList, null).subscribe((result) => {
      this.yearOfExperienceModelList = result.data;
    });
  }

  private populateIndustryOfExpertise() {
    this.apiService.get(apiLinks.api.mainService.lookup.getIndustryOfExpertiseList, null).subscribe((result) => {
      this.industryOfExpertiseModelList = this.sort.transform(result.data, 'asc', 'industryOfExperitise');
    });
  }

  private populateExpertise() {
    this.apiService.get(apiLinks.api.mainService.lookup.getExpertiseList, null).subscribe((result) => {
      this.expertiseModelList = this.sort.transform(result.data, 'asc', 'areaOfExpertise');
    });
  }

  private populateReasonsToApply() {
    this.apiService.get(apiLinks.api.mainService.lookup.getReasonToApplyList, null).subscribe((result) => {
      this.reasonToApplyModelList = this.sort.transform(result.data, 'asc', 'reasonToApply');
    });
  }

  private populatePreferredHourlyRates() {
    this.apiService.get(apiLinks.api.mainService.lookup.getAllPreferredHourlyRateList, null).subscribe((result) => {
      this.preferredHourlyRateModelList = result.data;
    });
  }

  private populateProjectsPerWeeks() {
    this.apiService.get(apiLinks.api.mainService.lookup.getAllProjectsPerWeekList, null).subscribe((result) => {
      this.projectsPerWeekModelList = result.data;
    });
  }
}
