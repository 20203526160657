<section class="reset-password__page" style="background-image: url('./assets/images/reset-password.png')">
  <div class="container">
    <form [formGroup]="forgetPasswordForm" (ngSubmit)="forgetPasswordRequestWithEmailSubmit(forgetPasswordEmail)">
      <div class="section-title text-start">
        <h3>{{ 'loginPage.forgotPass' | translate }}</h3>
        <p>{{ 'loginPage.emailPlaceHolder' | translate }} </p>
      </div>
      <div class="form-group">
        <label for="email">{{ 'loginPage.email' | translate }}</label>
        <div class="input-wrapper">
          <input #forgetPasswordEmail type="email" class="form-control"
                 required
                 name="email"
                 formControlName="email"
                 id="email"
                 placeholder="{{ 'loginPage.emailPlaceHolder' | translate }}">
          <ng-container *ngFor="let validation of forgetPasswordFormValidationMessages.email">
            <div class="invalid-feedback"
                 [style.display]="shouldShowError(forgetPasswordForm.get('email'), validation)">
              {{ validation.message | translate }}
            </div>
          </ng-container>
        </div>
      </div>
      <div class="row reset-actions">
        <div class="col-md-6">
          <div class="action">
            <button class="button filled" type="submit" [disabled]="forgetPasswordForm.invalid">{{ 'submit' | translate }}</button>
          </div>
        </div>
        <div class="col-md-6">
          <a [routerLink]="[routes.login]" class="anchor white-secondary">{{'cancel' | translate}}</a>
        </div>
      </div>
    </form>
  </div>
</section>
