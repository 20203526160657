import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ForgetPasswordRequestService} from "../../../service/auth/forget-password-request.service";
import {RoutersObject} from "../../../utils/constants/routers-object";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ComparePasswordsValidator} from "../../../utils/validators/compare-passwords.validator";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  routes = RoutersObject;
  forgetPasswordToken!: string;
  errorMessageOfServer!: string;
  updatePasswordOnResetRequestForm!: FormGroup;

  isSubmitting: boolean = false;
  submitButtonText: string = 'submit';
  errorMsg!: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private forgetPasswordRequestService: ForgetPasswordRequestService,
    private comparePasswordsValidator: ComparePasswordsValidator,
  ) {
  }

  ngOnInit(): void {
    this.getResetPasswordTokenAndCheckValidity();
    this.updatePasswordOnResetRequestForm = this.updatePasswordOnResetRequestFormGroup();
  }

  private getResetPasswordTokenAndCheckValidity(): void {
    this.forgetPasswordToken = this.activatedRoute.snapshot.paramMap.get('token') ?? '';
    this.forgetPasswordRequestService.showResetPasswordPageVerification(this.forgetPasswordToken).subscribe({
      next: () => {
        return;
      },
      error: () => {
        this.router.navigate([this.routes.login]).then();
      }
    })
  }

  public updatePasswordRequestAfterResetRequest() {
    // if (this.updatePasswordOnResetRequestForm?.invalid) {
    //   return;
    // }

    this.isSubmitting = true;
    this.submitButtonText = 'loading';

    if (this.updatePasswordOnResetRequestForm?.invalid) {
      this.updatePasswordOnResetRequestForm.markAllAsTouched();
      this.isSubmitting = false;
      this.submitButtonText = 'submit';
      return;
    }
    this.forgetPasswordRequestService.updatePasswordOnResetPasswordToken(this.updatePasswordOnResetRequestForm.value).subscribe({
      next: () => {
        this.router.navigate([this.routes.successPasswordChange]).then()
        this.errorMsg = '';
      },
      error: (errorMessage) => {
        this.errorMessageOfServer = errorMessage.error.message;
        this.errorMsg = errorMessage.error.message //"validationMsg.matchPassword";
        this.isSubmitting = false;
        this.submitButtonText = 'submit';
      },
      complete: () => {
        this.updatePasswordOnResetRequestForm?.reset();
        this.isSubmitting = false;
        this.submitButtonText = 'submit';
      }
    })
  }

  private updatePasswordOnResetRequestFormGroup(): FormGroup {
    return this.formBuilder.group({
        newPassword: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(50), Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%,./*?&])[A-Za-z\d$@$!%,./*?&].{8,}$")])],
        confirmNewPassword: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(50)])],
        token: [this.forgetPasswordToken, Validators.compose([Validators.required])],
      },
      {
        validators: [this.comparePasswordsValidator.match('newPassword', 'confirmNewPassword')]
      })
  }

  public shouldShowError(input?: any, validation?: { type: string; message: string }): string {
    if (input?.hasError(validation?.type) && (input?.dirty || input?.touched)) {
      return 'block';
    } else {
      return 'none';
    }
  }

  checkValidations(formGroup: FormGroup): void {
    if (formGroup.invalid) {
      formGroup.markAllAsTouched();
    }
  }

  public updatePasswordOnResetRequestFormValidationMessages = {
    newPassword: [
      {type: 'required', message: 'validationMsg.newPassword'},
      {type: 'minlength', message: 'validationMsg.minPasswordLength'},
      {type: 'maxlength', message: 'validationMsg.maxPasswordLength'},
      {type: 'matching', message: 'validationMsg.matchPassword'},
      {type: 'pattern', message: 'validationMsg.PasswordContainOneNumber'},
      {type: 'pattern', message: 'validationMsg.PasswordContainOneUppercase'},
      {type: 'pattern', message: 'validationMsg.PasswordContainOneSpecialCharacter'},
    ],
    confirmNewPassword: [
      {type: 'required', message: 'Confirm new password is required'},
      {type: 'minlength', message: 'validationMsg.minPasswordLength'},
      {type: 'maxlength', message: 'validationMsg.maxPasswordLength'},
      {type: 'matching', message: 'validationMsg.matchPassword'},
      {type: 'pattern', message: 'validationMsg.PasswordContainOneNumber'},
      {type: 'pattern', message: 'validationMsg.PasswordContainOneUppercase'},
      {type: 'pattern', message: 'validationMsg.PasswordContainOneSpecialCharacter'},
    ]
  }
}
