<div class="{{variant}} form-group" dir="{{ ('dir' | dir ) }}">
    <label *ngIf="variant == 'lookups' && label" for="{{ id }}" style="margin-top: -23px;">{{ label }}</label>
    <input [dir]="'dir'|dir" type="text" class="form-control search-input" placeholder="{{ placeholder }}"
        [formControl]="searchControl" (ngModelChange)="performSearch($event)" />

    <div class="search-container">
        <div class="search-results" *ngIf="inputExists">
            <div *ngFor="let companyType of ['listedCompanies', 'nonListedCompanies', 'serviceProviders']">
                <div class="d-flex align-items-center mt-5 mb-1">
                    <img [src]="companyTypeMapping[companyType].imgSrc" alt="">
                    <h4 class="ms-2">{{ companyTypeMapping[companyType].title | translate }}</h4>
                </div>
                <div class="horizontal-line"></div>
                <!-- Company List or Not Found -->
                <div *ngIf="companyData[companyType] && companyData[companyType].length > 0; else notFound">
                    <div class="profile-list" *ngFor="let company of companyData[companyType]" (click)="selectCompany(company, companyType)">
                        <div *ngIf="company.companyName">
                            <img *ngIf="company.companyLogoUrl; else notImageFound" [src]="fileUrl + company.companyLogoUrl"
                            class="company-logo" (error)="onImageError($event)">
                            <ng-template #notImageFound> 
                                <img class="company-logo" src="../../../../../../../../assets/images/default-image.png" alt="">
                            </ng-template>
                            {{ company.companyName }}
                            <hr class="profile-list-horizontal-line">
                        </div>
                    </div>
                </div>
                <!-- No Company Found -->
                <ng-template #notFound>
                    <div class="mt-2" *ngIf="companyType === 'serviceProviders'; else notSP">
                        {{'Not Found.'  | translate}}
                        <a class="hyperLink" (click)="routeToSP()">
                            {{ 'Request to add as SP' | translate }}
                        </a>
                    </div>
                    <ng-template #notSP>
                        <div class="mt-2" >
                            {{'Not Found.'  | translate}}
                            <a class="hyperLink" (click)="routeToRegisterForm()">
                                {{ 'Request to add as Company' | translate }}
                            </a>
                        </div>
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </div>

    <div *ngIf="variant == 'custom-select-search'" class="icon" dir="{{ ('dir' | dir ) }}">
        <i class="fa-solid fa-magnifying-glass" dir="{{ ('dir' | dir ) }}"></i>
    </div>

    <ng-container *ngIf="control.errors && control.touched">
        <p *ngIf="control.errors['required']" class="error-message">{{ 'Required field' | translate }}</p>
    </ng-container>
</div>