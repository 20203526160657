import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ThemeModule} from "./theme/theme.module";
import {JWT_OPTIONS, JwtHelperService} from "@auth0/angular-jwt";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {SortPipe} from "./utils/pipes/sort.pipe";
import {LookupService} from "./service/lookup/lookup.service";
import {NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule} from "ngx-ui-loader";
import {loaderConfig} from "./utils/constants/loader-config";
import {Observable} from "rxjs";
import {SharedModule} from "./shared/shared.module";
import {AuthInterceptorService} from "./service/auth/interceptor/auth.interceptor";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {NgSelectModule} from "@ng-select/ng-select";
import {ErrorInterceptor} from "./pages/sp-buyer-users/core/interceptors/error.interceptor";
import {ToastrModule} from "ngx-toastr";
import {LanguageInterceptor} from "./service/auth/interceptor/language.interceptor";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";

// export function initLookupApp(lookupService: LookupService) {
//   return async (): Promise<void> => {
//     return new Promise((resolve) => {
//       lookupService.preloadLookupData();
//       resolve();
//     });
//   };
// }

export function initLookupApp(lookupService: LookupService): () => Observable<any> {
  return () => lookupService.loadInitialStyles()
}

@NgModule({
  declarations: [
    AppComponent,
    SortPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ThemeModule,
    SharedModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule.forRoot(loaderConfig),
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot({}),
    NgSelectModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    
    NgMultiSelectDropDownModule.forRoot()

  ],
  providers: [
    {
      provide: JWT_OPTIONS,
      useValue: JWT_OPTIONS
    },
    JwtHelperService,
    {
      provide: APP_INITIALIZER,
      useFactory: initLookupApp,
      deps: [LookupService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    SortPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
