export let UserTypesObject = {
  companyUser: 3,
  individualShareholderUser: 4,
  institutionalShareholderUser: 5,
  individualInvestorUser: 6,
  institutionalInvestorUser: 7,
  independentCorporateFinancialConsultantUser: 8,
  buyerUser: 11,
  serviceProviderCompanyUser: 9,
}

export let CompanyTypesObject = {
  privateCompanyTypeId: 1,
}
