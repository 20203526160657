import { Component } from '@angular/core';
import {RoutersObject} from "../../../utils/constants/routers-object";

@Component({
  selector: 'app-success-password-change',
  templateUrl: './success-password-change.component.html',
  styleUrls: ['./success-password-change.component.scss']
})
export class SuccessPasswordChangeComponent {

  routes = RoutersObject;
}
