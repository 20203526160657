import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CountryLookupsComponent} from './components/country-lookups/country-lookups.component';
import {CategoryLookupsComponent} from './components/category-lookups/category-lookups.component';
import {TitleLookupsComponent} from './components/title-lookups/title-lookups.component';
import {CurrencyLookupsComponent} from './components/currency-lookups/currency-lookups.component';
import {UiModule} from "../../ui/ui.module";
import {
    CategorySubcategoryLookupsComponent
} from './components/category-subcategory-lookups/category-subcategory-lookups.component';
import {CityLookupsComponent} from './components/city-lookups/city-lookups.component';
import {BusinessTypeLookupsComponent} from './components/business-type-lookups/business-type-lookups.component';
import {
    DevelopmentStageLookupsComponent
} from './components/development-stage-lookups/development-stage-lookups.component';
import {IndustryGroupLookupsComponent} from './components/industry-group-lookups/industry-group-lookups.component';
import {SortLookupsComponent} from './components/sort-lookups/sort-lookups.component';
import {CompanyLookupsComponent} from './components/company-lookups/company-lookups.component';
import {CoreModule} from "../../../core/core.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {RequestTypeLookupsComponent} from './components/request-type-lookups/request-type-lookups.component';
import {RequestStatusComponent} from './components/request-status/request-status.component';
import {ProjectLookupsComponent} from "./components/project-lookups/project-lookups.component";
import {TranslateModule} from "@ngx-translate/core";
import {CountryCityLookupsComponent} from "./components/country-city-lookups/country-city-lookups.component";
import {PrivateCompanyLookupsComponent} from "./components/private-company-lookups/private-company-lookups.component";
import { AllCompaniesLookupsComponent } from './components/all-companies-lookups/all-companies-lookups.component';


@NgModule({
    declarations: [
        CountryLookupsComponent,
        CategoryLookupsComponent,
        TitleLookupsComponent,
        CurrencyLookupsComponent,
        CategorySubcategoryLookupsComponent,
        CityLookupsComponent,
        BusinessTypeLookupsComponent,
        DevelopmentStageLookupsComponent,
        IndustryGroupLookupsComponent,
        SortLookupsComponent,
        CompanyLookupsComponent,
        AllCompaniesLookupsComponent,
        RequestTypeLookupsComponent,
        RequestStatusComponent,
        ProjectLookupsComponent,
        CountryCityLookupsComponent,
        PrivateCompanyLookupsComponent,
        AllCompaniesLookupsComponent
    ],
    exports: [
        CountryLookupsComponent,
        CategoryLookupsComponent,
        CurrencyLookupsComponent,
        TitleLookupsComponent,
        CategorySubcategoryLookupsComponent,
        CityLookupsComponent,
        IndustryGroupLookupsComponent,
        DevelopmentStageLookupsComponent,
        BusinessTypeLookupsComponent,
        SortLookupsComponent,
        CompanyLookupsComponent,
        AllCompaniesLookupsComponent,
        RequestTypeLookupsComponent,
        RequestStatusComponent,
        ProjectLookupsComponent,
        CountryCityLookupsComponent,
        PrivateCompanyLookupsComponent
    ],
    imports: [
        CommonModule,
        UiModule,
        CoreModule,
        ReactiveFormsModule,
        FormsModule,
        TypeaheadModule,
        TranslateModule
    ]
})
export class LookupsModule {
}
