import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class DirectionService {
    platformId: Object;

    constructor(@Inject(PLATFORM_ID) platformId: Object) {
        this.platformId = platformId
    }

    isRtl() {
        if (isPlatformBrowser(this.platformId)) {
          // below code check and change according to language
          if (document.dir == ""){
            const lang= localStorage.getItem("defaultLanguage") || 'en';
            if (lang == "en"){
              document.dir = 'ltr';
            }
            else if (lang == "ar") {
              document.dir = 'rtl';
            }
          }
            return document.dir == 'rtl'
        } else {

            return false;
        }
    }

}
