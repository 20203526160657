export const environment = {
  production: true,
  apiUrl: "https://sm.gfm.support",
  mixDataUrl: "https://data.feedgfm.com/mix2/ClientServiceProvider?",
  dataAccessApiUrl: "https://sm.gfm.support/data-service",
  fileUrl: "https://d1xmw4romiebhv.cloudfront.net/",
  nodeAwsChime: 'https://sm.gfm.support/meeting-service',
  financialHighlightsUrl: "https://ir.directfn.com/smfinancial/ir/smfinancial/en/financial/sm/",
  financialHighlightsUrlAr: "https://ir.directfn.com/smfinancial/ir/smfinancial/ar/financial/sm/",
  pk: 'pk_test_51NdRLFLmNFm0dQ1r6DjBjajyQjuOeK9zrylw1mi4NsM3Rt5nQxpglper9PgrVYyIt4zjcz0tThN40MsVqo8Wh4pb00JYJhWzbW',
  fileSize : 20480000,
  fileSizeStr: "20 MB",
  allowedFileFormat : ".pdf,.png,.jpeg,.jpg",
  allowedImageFormats : ".png,.jpeg,.jpg",
  allowedFileContentType : "application/pdf,image/png,image/jpeg,image/jpg",
  arVersion: 'https://cf.sm.gfm.support/',
  enVersion: 'https://en-cf.sm.gfm.support/',
  defaultCountry: 'SA'
};
