<section class="login-page" style="background-image: url('./assets/images/registration-shareholder.png')">
  <div class="container scale-element" #login>
    <form (ngSubmit)="onLogin()" [formGroup]="loginForm" class="needs-validation"
          id="login-page-form" novalidate>
      <div class="section-title text-start">
        <h3>{{ 'loginPage.welcomeTo' | translate}}</h3>
        <p>{{ 'loginPage.subtitle' | translate}} </p>
      </div>

      <div class="form-error-message d-none ">
        <div class="wrapper d-flex justify-content-between align-items-center">
          <div class="error-wrapper-1 d-flex align-items-center">
            <div class="icon">
              <img alt="" src="./assets/images/icons/error.svg">
            </div>
            <div class="error-content">
              <h5>Please fill all required fields</h5>
              <span class="subtitle">Lorem lpsum dolor sit amet, consectetur adipiscing elit.</span>
            </div>
          </div>
          <div class="error-wrapper-2">
            <button class="body-2" id="error-message-login-close-button">X</button>
          </div>
        </div>
      </div>

      <div class="form-group login" [ngClass]="commonConfigService.currentLanguage() == 'ar'? 'langAr' : ''">
        <label for="login-user">{{ 'loginPage.email' | translate}}</label>
        <input class="form-control"
               formControlName="username"
               id="login-user"
               name="username"
               placeholder="{{ 'loginPage.emailPlaceHolder' | translate}}"
               required
               type="email">
        <ng-container *ngFor="let validation of loginFormValidationMessages.username">
          <div [style.display]="shouldShowError(loginForm.get('username'), validation)"
               class="invalid-feedback">
            {{validation.message | translate}}
          </div>
        </ng-container>

      </div>
      <div class="form-group login" [ngClass]="commonConfigService.currentLanguage() == 'ar'? 'langAr' : ''">
        <label for="login-pass">{{ 'loginPage.password' | translate}}</label>
        <div class="input-wrapper">
          <input autocomplete="on" class="form-control"
                 formControlName="password"
                 id="login-pass"
                 minlength="8"
                 name="password"
                 placeholder="{{ 'loginPage.passwordPlaceHolder' | translate}}"
                 required type="password">
          <button
            class="show-password flex-center"
            type="button">
            <i class="far fa-eye" showHidePassword></i>
          </button>
        </div>
        <ng-container *ngFor="let validation of loginFormValidationMessages.password">
          <div [style.display]="shouldShowError(loginForm.get('password'), validation)"
               class="invalid-feedback">
            {{validation.message | translate }}
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-check">
            <input class="form-check-input" id="loginCheck" type="checkbox">
            <label class="form-check-label" for="loginCheck">
              {{ 'loginPage.rememberMe' | translate}}
            </label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="forget-password__login">
            <a [routerLink]="[routes.forgetPassword]">{{ 'loginPage.forgotPass' | translate}}</a>
          </div>
        </div>
      </div>
      <div *ngIf="errorMsg" class="alert alert-danger mb-3 mt-3 d-flex justify-content-between" role="alert">
        {{errorMsg | translate}}
        <button aria-label="Close" class="btn-close" data-bs-dismiss="alert" type="button"></button>
      </div>

      <div class="login-button">
        <div class="action">
          <!--      <button class="button filled" type="submit" [disabled]="loginForm.invalid">Log in</button>-->
          <button (click)="checkValidations(loginForm)" [disabled]="loginForm.invalid"
                  class="button filled" type="submit">
            <i *ngIf="isSubmitting" class="fa fa-spinner fa-spin"></i>{{submitButtonText | translate }}
          </button>
        </div>
      </div>
      <div class="sign-up__login text-center">
        <a [routerLink]="[routes.registration]">{{ 'loginPage.haveAccount' | translate}} <span
          class="active">{{ 'loginPage.signUp' | translate}}</span></a>
      </div>
    </form>
  </div>
</section>
