<div class="offer-shares-modal fade show">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-body">
        <div class="modal-wrapper-2">
          <div class="success-icon">
            <img src="././assets/images/icons/success-submission.svg" alt="">
          </div>
          <div class="content">
            <h3>{{dialogMessageModel.heading ? (dialogMessageModel.heading | translate) : ''}}</h3>
            <p class="body-3" *ngIf="!dialogMessageModel.linkName">
              {{dialogMessageModel.message ? (dialogMessageModel.message | translate) : ''}}
            </p>

            <p class="body-3" *ngIf="dialogMessageModel.linkName">

              {{dialogMessageModel.message ? (dialogMessageModel.message | translate) : ''}}
              <a routerLink="{{dialogMessageModel.routerLink}}" (click)="close()" style="text-decoration: underline">{{dialogMessageModel.linkName | translate}}</a>
              {{dialogMessageModel.secondMessage ? (dialogMessageModel.secondMessage  | translate) : ''}}

            </p>

          </div>
          <div class="action text-center" *ngIf="!dialogMessageModel.showBtn">
            <button type="button" class="anchor filled" (click)="close()">{{'OK' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
