import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {exhaustMap, take} from 'rxjs/operators';
import {AuthService} from "../auth.service";
import {CommonConfigService} from "../../../utils/common/common-config.service";
import {Observable} from "rxjs";
import { environment } from 'src/environments/environment';


@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private commonConfigService: CommonConfigService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the current language from the service
    const currentLanguage = this.commonConfigService.currentLanguage();
    // const urlMatches = request.url.includes('/editorial-news/priortized-news');
    const urlMatches = request.url.includes('https://data.feedgfm.com');

    // Clone the request and add the 'Accept-Language' header
    if(!urlMatches) {
      const modifiedRequest = request.clone({
        setHeaders: {
          'Accept-Language': currentLanguage,
          'Country-Code': 'SA',
          // ...(urlMatches && {'Country-Code': 'SA'})
        },
      });

      // Pass the modified request to the next interceptor or handler
      return next.handle(modifiedRequest);
    } else {
      const modifiedRequest = request.clone({
        setHeaders: {
          'Accept-Language': currentLanguage,
          // 'Country-Code': 'SA',
          // ...(urlMatches && {'Country-Code': 'SA'})
        },
      });

      // Pass the modified request to the next interceptor or handler
      return next.handle(modifiedRequest);
    }

  }
}
