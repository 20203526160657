import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PublicHeaderComponent} from "./components/headers/public/public-header.component";
import {FooterComponent} from "./components/footer/footer.component";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {CompanyUserHeaderComponent} from './components/headers/company-user-header/company-user-header.component';
import {
  ShareholderUserHeaderComponent
} from './components/headers/shareholder-user-header/shareholder-user-header.component';
import {InvestorUserHeaderComponent} from './components/headers/investor-user-header/investor-user-header.component';
import {HttpClientModule} from "@angular/common/http";
import {TranslateModule} from "@ngx-translate/core";
import {
  BuyerAndServiceProviderHeaderComponent
} from "./components/headers/buyer-and-service-provider-header/buyer-and-service-provider-header.component";
import {DashboardHeaderComponent} from './components/headers/dashboard-header/dashboard-header.component';
import {SlickCarouselModule} from "ngx-slick-carousel";
import {CoreModule} from "../pages/sp-buyer-users/core/core.module";
import { LookupsModule } from '../pages/sp-buyer-users/shared/sp/lookups/lookups.module';


@NgModule({
  declarations: [
    PublicHeaderComponent,
    CompanyUserHeaderComponent,
    ShareholderUserHeaderComponent,
    InvestorUserHeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    BuyerAndServiceProviderHeaderComponent,
    PageNotFoundComponent,
    DashboardHeaderComponent
  ],
  exports: [
    PublicHeaderComponent,
    CompanyUserHeaderComponent,
    ShareholderUserHeaderComponent,
    InvestorUserHeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    BuyerAndServiceProviderHeaderComponent,
    PageNotFoundComponent,
    DashboardHeaderComponent
  ],
    imports: [
        CommonModule,
        RouterLink,
        HttpClientModule,
        TranslateModule,
        RouterLinkActive,
        SlickCarouselModule,
        CoreModule,
        LookupsModule
    ]
})
export class ThemeModule {
}
