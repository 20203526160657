import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RoutersObject} from "../../../utils/constants/routers-object";
import {ForgetPasswordRequestService} from "../../../service/auth/forget-password-request.service";
import {MetaTagsService} from "../../../utils/common/meta-tags.service";
import {default as pagesTitle} from "src/app/utils/constants/titles.json"
import {CommonConfigService} from "../../../utils/common/common-config.service";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  routes = RoutersObject;
  forgetPasswordForm!: FormGroup;
  errorMessageOfServer!: string;

  constructor(
    private forgetPasswordRequestService: ForgetPasswordRequestService,
    private router: Router,
    private formBuilder: FormBuilder,
    public metaTagsService: MetaTagsService,
    public commonConfigService: CommonConfigService,
  ) {
  }

  ngOnInit(): void {
    this.metaTagsService.updateTitle(this.commonConfigService.currentLanguage() == "en" ? pagesTitle.titles.forgetPassword.en : pagesTitle.titles.forgetPassword.ar);
    this.forgetPasswordForm = this.forgetPasswordFormGroup();
  }

  public forgetPasswordRequestWithEmailSubmit(forgetPasswordEmail: any) {
    if (this.forgetPasswordForm?.invalid) {
      return;
    }
    this.forgetPasswordRequestService.initiateForgetPasswordRequest(this.forgetPasswordForm.value).subscribe({
      next: () => {
        this.router.navigate([this.routes.successReset], {queryParams: {email: forgetPasswordEmail.value}}).then()
      },
      error: (errorMessage) => {
        this.errorMessageOfServer = errorMessage;
      },
      complete: () => {
        this.forgetPasswordForm?.reset();
      }
    })
  }

  private forgetPasswordFormGroup(): FormGroup {
    return this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  public shouldShowError(input?: any, validation?: { type: string; message: string }): string {
    if (input?.hasError(validation?.type) && (input?.dirty || input?.touched)) {
      return 'block';
    } else {
      return 'none';
    }
  }

  public forgetPasswordFormValidationMessages = {
    email: [
      {type: 'required', message: 'validationMsg.emailRequired'},
      {type: 'email', message: 'Should be a valid email'}
    ]
  }
}
