<div class="shareholder-change__password fade show">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-header company-profile">
        <div class="header-wrapper">
          <h4>{{'Change password' | translate}}</h4>
          <span class="body-3">{{'loginPage.enterNewDetails' | translate}}</span>
        </div>
        <button *ngIf="!data?.isFirstTime" type="button" class="close" (click)="close()"><i
          class="fa-solid fa-x"></i></button>
      </div>
      <form [formGroup]="updatePasswordOnResetRequestForm" (ngSubmit)="onSubmitResetPassword()" class="change-password-dialog">
        <div class="modal-body">
          <div class="form-group">
            <label for="current-pass">{{'Current Password' | translate}}</label>
            <div class="input-wrapper">
              <input type="password" class="form-control"
                     required
                     name="newPassword"
                     formControlName="currentPassword"
                     id="current-pass"
                     autocomplete="on"
                     placeholder="{{'Enter your Current password' | translate}}">
              <button
                type="button"
                class="show-password flex-center">
                <i showHidePassword class="far fa-eye"></i>
              </button>
            </div>
            <ng-container
              *ngFor="let validation of updatePasswordOnResetRequestFormValidationMessages.currentPassword">
              <div class="invalid-feedback"
                   [style.display]="shouldShowError(updatePasswordOnResetRequestForm.get('currentPassword'), validation)">
                {{validation.message | translate}}
              </div>
            </ng-container>
          </div>
          <div class="form-group">
            <label for="new-pass">{{'New Password' | translate}}</label>
            <div class="input-wrapper">
              <input type="password" class="form-control"
                     required
                     name="newPassword"
                     formControlName="newPassword"
                     id="new-pass"
                     autocomplete="on"
                     placeholder="{{'Enter your New password' | translate}}">
              <button
                type="button"
                class="show-password flex-center">
                <i showHidePassword class="far fa-eye"></i>
              </button>
            </div>
            <ng-container *ngFor="let validation of updatePasswordOnResetRequestFormValidationMessages.newPassword">
              <div class="invalid-feedback"
                   [style.display]="shouldShowError(updatePasswordOnResetRequestForm.get('newPassword'), validation)">
                {{validation.message | translate}}
              </div>
            </ng-container>
            <ng-container
              *ngFor="let validation of updatePasswordOnResetRequestFormValidationMessages.newPassword">
              <div class="invalid-feedback"
                   [style.display]="showFormError(updatePasswordOnResetRequestForm, validation)">
                {{validation.message | translate}}
              </div>
            </ng-container>
          </div>
          <div class="form-group">
            <label for="confirm-new-pass">{{'Confirm New Password' | translate}}</label>
            <div class="input-wrapper">
              <input type="password" class="form-control"
                     required
                     name="confirmNewPassword"
                     formControlName="confirmNewPassword"
                     id="confirm-new-pass"
                     autocomplete="on"
                     placeholder="{{'Enter your New Confirm password' | translate}}">
              <button
                type="button"
                class="show-password flex-center">
                <i showHidePassword class="far fa-eye"></i>
              </button>
            </div>
            <ng-container
              *ngFor="let validation of updatePasswordOnResetRequestFormValidationMessages.confirmNewPassword">
              <div class="invalid-feedback"
                   [style.display]="shouldShowError(updatePasswordOnResetRequestForm.get('confirmNewPassword'), validation)">
                {{validation.message | translate}}
              </div>
            </ng-container>
            <ng-container
              *ngFor="let validation of updatePasswordOnResetRequestFormValidationMessages.confirmNewPassword">
              <div class="invalid-feedback"
                   [style.display]="showFormError(updatePasswordOnResetRequestForm, validation)">
                {{validation.message | translate}}
              </div>
            </ng-container>
          </div>
        </div>
        <div *ngIf="errorMsg" class="alert alert-danger mb-3 mt-3 d-flex justify-content-between" role="alert">
          {{errorMsg}}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div class="modal-footer">
          <button type="submit" class="button filled"
                  (click)="checkValidations(updatePasswordOnResetRequestForm)">
            <i *ngIf="isSubmitting" class="fa fa-spinner fa-spin"></i>{{submitButtonText | translate}}
          </button>
          <div class="Cancel" data-bs-dismiss="modal" *ngIf="!data?.isFirstTime">
            <button type="reset" class="button white-grey" (click)="close()">{{'cancel' | translate}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
