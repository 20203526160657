export const href = {
  login: 'auth/login',
  forgetPassword: 'auth/forget-password',
  register: '/sp/register',
  registerSp: '/sp/register/sp',
  registerSearchCompany: '/sp/register/search-company',
  updatePassword: 'auth/update-password',
  home: '/sp/',
  editProfile: 'edit',
  spClaimOwnership: '/sp/register/sp-claim-ownership/',
  fintechClaimOwnership: '/fintech/claim-ownership/',
  spProfileView: '/sp/sp/',
  quotation: '/sp/quotations',
  spPublicHomeSearchResult: '/sp/public/public-home-search-result',
  requirements: '/sp/public/requirements',
  confirmPlan: '/sp/plan/confirm/',
  featureYourCompany: '/sp/plan/feature-your-company',
  notFound: '/sp/404',
  requests: '/sp/requests',
  otherRequests: '/sp/requests/other',
  logOut: 'auth/logout',
  subscriptionStep: '/sp/sp-subscription-step',
  spScreener: '/sp/sp-screener',
  buyerHome: '/sp/buyer',
  spHome: '/sp/service-provider',
  buyerProfile: '/sp/buyer-profile',
  spProfile: '/sp/sp-profile',
  spViewProfile: '/sp/sp-profile/sp-view-profile',
  userProfileDropDown: '../sp/sp/',
  PublicRequirements: '/sp/public/requirements',
  RegisterSearchCompany: '/sp/register/search-company',
  PublicAccessBySp: '/sp/public/access-by-service-provider',
  PublicTopCompanies: '/sp/public/top-companies',
  PublicLatestCompanies: '/sp/public/latest-companies',
  BuyerRegister: '/sp/register/buyer',
  guestHome: '/sp/sp',
}
