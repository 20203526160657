import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class NewsTitleEncodedService {

  getEncodedTitle(headline: string, lang : string = 'en'): string {
    headline = headline ?? '';
    let encodedTitle = '';
    if(lang === 'ar'){
      encodedTitle = headline.replace(/\s+/g, '-')
    }else{
      const sanitizedTitle = headline.toLowerCase().replace(/[^a-z0-9\s-]/g, '');
       encodedTitle = encodeURIComponent(sanitizedTitle.replace(/\s+/g, '-'));
    }
    return encodedTitle;
  }

}
