import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[showHidePassword]'
})
export class ShowHidePasswordDirective {
  showPwd = false;

  constructor(private el: ElementRef) {
    let pwdField = this.el.nativeElement.parentNode.parentNode.firstElementChild;

    this.el.nativeElement.addEventListener('click', () => {
      this.toggle(pwdField);
    });
  }

  toggle(inputField: any) {

    this.showPwd = !this.showPwd

    if (this.showPwd) {
      inputField.setAttribute('type', 'text');
      this.el.nativeElement.classList.remove('fa-eye');
      this.el.nativeElement.classList.add('fa-eye-slash')
    } else {
      inputField.setAttribute('type', 'password');
      this.el.nativeElement.classList.remove('fa-eye-slash');
      this.el.nativeElement.classList.add('fa-eye')
    }
  }
}
