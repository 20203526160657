import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private BASE_URL: string = environment.apiUrl;
  private BASE_URL_MIX: string = environment.mixDataUrl;
  private DATA_ACCESS_BASE_URL: string = environment.dataAccessApiUrl;
  private requestHeaders = new HttpHeaders();
  private formData: any;
  private createFormData: any;

  constructor(
    private http: HttpClient,
  ) {
  }

  public getMixData(path?: any, param?: any): Observable<any> {
    //this.updateHeader();
    return this.http.get(this.BASE_URL_MIX + path, {headers: this.requestHeaders, params: param}).pipe(
      map((res: any) => res),
      catchError(this.handleErrors.bind(this)));
  }

  public post(path?: any, param?: any, config?: any): Observable<any> {
    this.updateHeader();
    return this.http.post(this.BASE_URL + path, param, {headers: this.requestHeaders}).pipe(
      map((res: any) => res),
      catchError((err) => {
        return this.handleErrors(err, config)
      }));
  }

  public formPost(path?: any, param?: any): Observable<any> {

    return this.http.post(this.BASE_URL + path, this.convertObjectToFormData(param)).pipe(
      map((res: any) => res),
      catchError(this.handleErrors.bind(this)));
  }

  public postFormData(path?: any, formData?: FormData): Observable<any> {

    return this.http.post(this.BASE_URL + path, formData).pipe(
      map((res: any) => res),
      catchError(this.handleErrors.bind(this)));
  }

  public postFormDataT<T>(path?: any, formData?: FormData): Observable<any> {

    return this.http.post<T>(this.BASE_URL + path, formData).pipe(
      map((res: any) => res),
      catchError(this.handleErrors.bind(this)));
  }

  public putFormDataT<T>(path?: any, formData?: FormData): Observable<any> {

    return this.http.put<T>(this.BASE_URL + path, formData).pipe(
      map((res: any) => res),
      catchError(this.handleErrors.bind(this)));
  }

  public get(path?: any, param?: any): Observable<any> {
    this.updateHeader();
    return this.http.get(this.BASE_URL + path, {headers: this.requestHeaders, params: param}).pipe(
      map((res: any) => res),
      catchError(this.handleErrors.bind(this)));
  }

  public getChime(path?: any, param?: any): Observable<any> {
    this.updateHeader();
    return this.http.get(path, {headers: this.requestHeaders, params: param}).pipe(
      map((res: any) => res),
      catchError(this.handleErrors.bind(this)));
  }

  public getDataAccess(path?: any, param?: any): Observable<any> {
    this.updateHeader();
    return this.http.get(this.DATA_ACCESS_BASE_URL + path, {headers: this.requestHeaders, params: param}).pipe(
      map((res: any) => res),
      catchError(this.handleErrors.bind(this)));
  }

  public postT<T>(path?: any, param?: any, config?: any): Observable<any> {
    this.updateHeader();
    return this.http.post<T>(this.BASE_URL + path, param, {headers: this.requestHeaders}).pipe(
      map((res: any) => res),
      catchError((err) => {
        return this.handleErrors(err, config)
      }));
  }

  public putT<T>(path?: any, param?: any, config?: any): Observable<any> {
    this.updateHeader();
    return this.http.put<T>(this.BASE_URL + path, param, {headers: this.requestHeaders}).pipe(
      map((res: any) => res),
      catchError((err) => {
        return this.handleErrors(err, config)
      }));
  }

  public deleteT<T>(path?: any, param?: any, config?: any): Observable<any> {
    this.updateHeader();
    return this.http.delete<T>(this.BASE_URL + path, {headers: this.requestHeaders, params: param}).pipe(
      map((res: any) => res),
      catchError(this.handleErrors.bind(this)));
  }

  public postDataAccess(path?: any, param?: any, config?: any): Observable<any> {
    this.updateHeader();
    return this.http.post(this.DATA_ACCESS_BASE_URL + path, param, {headers: this.requestHeaders}).pipe(
      map((res: any) => res),
      catchError((err) => {
        return this.handleErrors(err, config)
      }));
  }

  public getDataAccessT<T>(path?: any, param?: any): Observable<T> {
    this.updateHeader();
    return this.http.get<T>(this.DATA_ACCESS_BASE_URL + path, {headers: this.requestHeaders, params: param}).pipe(
      map((res: any) => res));
  }

  public getT<T>(path?: any, param?: any): Observable<T> {
    this.updateHeader();
    return this.http.get<T>(this.BASE_URL + path, {headers: this.requestHeaders, params: param}).pipe(
      map((res: any) => res));
  }

  public delete(path?: any, param?: any): Observable<any> {
    this.updateHeader();
    return this.http.delete(this.BASE_URL + path, {headers: this.requestHeaders, params: param}).pipe(
      map((res: any) => res),
      catchError(this.handleErrors.bind(this)));
  }

  public put(path?: any, param?: any): Observable<any> {
    this.updateHeader();
    return this.http.put(this.BASE_URL + path, param, {headers: this.requestHeaders}).pipe(
      map((res: any) => res),
      catchError(this.handleErrors.bind(this)));
  }

  public formPut(path?: any, param?: any): Observable<any> {

    return this.http.put(this.BASE_URL + path, this.convertObjectToFormData(param)).pipe(
      map((res: any) => res),
      catchError(this.handleErrors.bind(this)));
  }

  private updateHeader() {
    this.requestHeaders = new HttpHeaders()
      .append('Content-Type', 'application/json; charset=utf-8')
    // .append('Authorization', 'Bearer');
  }

  private handleErrors(err: any, config?: any): any {

    let errorMessage = "Error Occurred while performing required action";

    if (err) {
      errorMessage = err;
    }

    if (!(config && config.skipErrorToaster))

      return throwError(err);
  }

  private convertObjectToFormData(mainObject: any, formData = new FormData()) {

    this.formData = formData;

    this.createFormData = (data: any, subKeyStr = '') => {
      Object.keys(data).forEach(key => {
        let value = data[key];
        let subKeyStrTrans = subKeyStr + key;
        if (Array.isArray(data[key])) {
          data[key].forEach((item: any, index: number) => {
            this.createFormData(item, subKeyStrTrans + '[' + index + ']' + '.')
          })
        } else if (Object.prototype.toString.call(data[key]) === "[object Object]" && data[key] !== null) {
          this.createFormData(data[key], subKeyStrTrans + '.')
        } else {
          if (value == null || value == undefined || value == '') {
            return;
          }
          if (Object.prototype.toString.call(value) === '[object Date]') {
            value = this.formateDate(value);
          }
          this.formData.append(subKeyStrTrans, value ? value : '')
        }
      })
    }

    this.createFormData(mainObject);

    return this.formData;
  }

  formateDate(value: Date){
    let _unformattedDate = new Date(value);
    let month = (_unformattedDate.getMonth() + 1) <= 9 ? '0'+ (_unformattedDate.getMonth() + 1) : _unformattedDate.getMonth() +1;
    let day = (_unformattedDate.getDate()) <= 9 ? '0'+ (_unformattedDate.getDate()) : _unformattedDate.getDate();
    let year = _unformattedDate.getFullYear();

    return year +'-'+month+'-'+day;
}
}
