import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogMessageModel} from "../../../service/shared/dialog-message.model";
import {RoutersObject} from "../../../utils/constants/routers-object";
import {Router} from "@angular/router";

@Component({
  selector: 'app-success-message-dialog',
  templateUrl: './success-message-dialog.component.html',
  styleUrls: ['./success-message-dialog.component.scss']
})
export class SuccessMessageDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SuccessMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogMessageModel: DialogMessageModel,
    private route : Router
  ) {
    this.dialogMessageModel;
  }

  close(): void {
    this.dialogRef.close();
    if(this.dialogMessageModel.isRoute){
      this.route.navigate([this.dialogMessageModel.routerLink]).then();
    }
  }
}
