<section class="registration-wizard submission-page"
         style="background-image: url('../../../../../assets/images/registration-company.png')"
         xmlns="http://www.w3.org/1999/html">
  <div class="container">

    <div class="submission-card">
      <div class="success-icon">
        <img src="../../../../../assets/images/icons/success-submission.svg" alt="">
        <h3>{{"congratulations" | translate}}!</h3>
      </div>
      <div class="content">
        <h4>{{"You have successfully confirmed your email" | translate}}</h4>
        <p class="body-3 ">{{"investorCongratsSubMsg" | translate}}
        </p>
      </div>
      <div class="action text-center">
        <a routerLink="/home" class="anchor filled">{{"OK" | translate}}</a>
      </div>
    </div>
  </div>


</section>
