import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {
 public _splashScreenOb$ = new Subject();
  constructor() { }

  hideSplashScreen(){
    this._splashScreenOb$.next(false)
  }

  showSplashScreen(){
    this._splashScreenOb$.next(true)
  }
}
