import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {default as apiLinks} from "src/app/utils/constants/api-links.json";
import {RoutersObject} from "../../utils/constants/routers-object";

@Injectable({providedIn: 'root'})
export class ForgetPasswordRequestService {
  private routes = RoutersObject;

  constructor(
    private router: Router,
    private apiService: ApiService,
  ) {
  }

  /**
   * Initiate Forget Password Request on forget password page
   */
  public initiateForgetPasswordRequest(forgetPasswordObj: any): Observable<any> {
    return this.apiService.post(apiLinks.api.mainService.authentication.forgetPasswordRequest, forgetPasswordObj);
  }

  /**
   * Show Reset Password Page if the token is valid
   */
  public showResetPasswordPageVerification(token: string): Observable<any> {
    return this.apiService.get(apiLinks.api.mainService.authentication.resetPasswordTokenVerification + token);
  }

  /**
   * Update Password after Reset Password Request
   */
  public updatePasswordOnResetPasswordToken(updatePasswordRequestModel: any): Observable<any> {
    return this.apiService.post(apiLinks.api.mainService.authentication.updatePasswordOnResetRequest, updatePasswordRequestModel);
  }

  /**
   * Update Password after Reset Password Request
   */
  public updateResetPassword(updatePasswordRequestModel: any): Observable<any> {
    return this.apiService.post(apiLinks.api.mainService.authentication.updateResetRequest, updatePasswordRequestModel);
  }

}
