import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {orderBy} from 'lodash';

export type SortOrder = 'asc' | 'desc';

@Pipe({name: 'SortPipe'})
@Injectable()
export class SortPipe implements PipeTransform {
    transform(value: any[], order: SortOrder | string = 'asc', column?: string): any {

        if (!value || order === '' || !order) {
            return value;
        } // no array
        if (value.length <= 1) {
            return value;
        } // array with only one item
        if (!column || column === '') {
            if (order === 'asc') {
                return value.sort()
            } else {
                return value.sort().reverse();
            }
        } // sort 1d array
        let rowOrder: boolean | 'asc' | 'desc' = 'asc';
        if (order === 'desc')
            rowOrder = 'desc';
        return orderBy(value, [column], [rowOrder]);
    }
}
