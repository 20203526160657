import {AfterViewInit, Component, OnInit, Renderer2} from '@angular/core';
import {AuthService} from "./service/auth/auth.service";
import {LanguageHandlerService} from "./service/language/language-handler.service";
import {TranslateService} from "@ngx-translate/core";
import { SplashScreenService } from './service/splash-screen.service';
import { LookupService } from './service/lookup/lookup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'sm-fe';
  private showOnLoad = localStorage.getItem('initialLoad');
  // public showSplash: boolean = true;
  constructor(
    private authService: AuthService,public langHandler: LanguageHandlerService, private langTranslator: TranslateService,
    private lookupService: LookupService, private splashService : SplashScreenService
  ) {
  }
  ngAfterViewInit(): void {
    this.lookupService.preloadInitLookupData().subscribe(()=> {
      this.lookupService.initialisationCompleted.next(true);
  })
  }

  ngOnInit() {
    window.addEventListener('beforeunload', () => {
     
      if(this.showOnLoad && this.showOnLoad == 'true'){
        
        this.splashService.hideSplashScreen()
      }else{
        localStorage.setItem('initialLoad', 'true')
        this.splashService.showSplashScreen()
      }
    });

    window.addEventListener('load', () => {
      this.splashService.hideSplashScreen()

    });

    this.authService.autoLogin();
    //this.changeLanguageOnApplicationLoad();

  }

  // changeLanguageOnApplicationLoad(){
  //   let storedLang = localStorage.getItem('defaultLanguage');
  //   let defLang = '';
  //   if (storedLang == null) {
  //     defLang = this.langHandler.arLangCode;
  //     localStorage.setItem('defaultLanguage', defLang);
  //   } else {
  //     defLang = storedLang ?? this.langHandler.enLangCode;
  //   }
  //  this.langHandler.loadStyle(`lang-${defLang}.css`, defLang);
  //  this.langTranslator.setDefaultLang(defLang);
  //  this.langTranslator.setTranslation(defLang, defLang === 'en' ? enLang : arLang);
  // }
}
