import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthenticationRoutingModule} from './authentication-routing.module';
import {LoginComponent} from './login/login.component';
import {AuthenticationComponent} from "./authentication.component";
import {ThemeModule} from "../../theme/theme.module";
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ReactiveFormsModule} from "@angular/forms";
import {ForgetPasswordComponent} from './forget-password/forget-password.component';
import {SuccessResetComponent} from './success-reset/success-reset.component';
import {SuccessPasswordChangeComponent} from './success-password-change/success-password-change.component';
import {ShowHidePasswordDirective} from "../../utils/directives/show-hide-password.directive";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [
    LoginComponent,
    AuthenticationComponent,
    ForgetPasswordComponent,
    SuccessResetComponent,
    ResetPasswordComponent,
    SuccessPasswordChangeComponent,
    ShowHidePasswordDirective
  ],
  exports: [
    ShowHidePasswordDirective
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    ThemeModule,
    ReactiveFormsModule,
    TranslateModule,
  ]
})
export class AuthenticationModule {
}
