import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from "./theme/components/page-not-found/page-not-found.component";
import {default as feRoutes} from "src/app/utils/constants/fe-routes.json";
import {AuthGuard} from "./service/auth/guard/auth.guard";
import {AuthorizationGuard} from "./service/auth/guard/authorization.guard";
import {AppComponent} from "./app.component";
import {UserTypesObject} from "./utils/constants/user-types-object";
import {EmailComfirmationComponent} from './shared/components/email-comfirmation/email-comfirmation.component';
import {ChangePasswordComponent} from './shared/components/change-password/change-password.component';
import { AwsChimeMeetingComponent } from './shared/components/aws-chime-meeting/aws-chime-meeting.component';

const routes: Routes = [
  {path: feRoutes.routes.app.path, pathMatch: 'full', redirectTo: feRoutes.routes.app.publicModule.path},
  {
    path: feRoutes.routes.app.path,
    component: AppComponent,
    children: [
      {
        path: feRoutes.routes.app.publicModule.path,
        loadChildren: () => import('./pages/public/public.module').then(m => m.PublicModule)
      },
      {
        path: feRoutes.routes.app.authenticationModule.path,
        loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule)
      },
      {
        path: feRoutes.routes.app.registrationModule.path,
        loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule)
      },
      {
        path: feRoutes.routes.app.changePasswordFirstTime,
        component: ChangePasswordComponent
      },
      {
        path: feRoutes.routes.app.dashboardsModule.path,
        data: {
          allowedUserTypes: [
            UserTypesObject.companyUser,
            UserTypesObject.individualShareholderUser,
            UserTypesObject.institutionalShareholderUser,
            UserTypesObject.individualInvestorUser,
            UserTypesObject.institutionalInvestorUser,
            UserTypesObject.serviceProviderCompanyUser,
            UserTypesObject.buyerUser,
          ]
        },
        loadChildren: () => import('./pages/dashboards/dashboards.module').then(m => m.DashboardsModule),
        canActivate: [AuthGuard, AuthorizationGuard]
      },
      {
        path: 'sp',
        loadChildren: () => import('../app/pages/sp-buyer-users/features/sp/sp.module').then((m) => m.SpModule)
      },
      {
        path: "join-meeting/:mid",
        component: AwsChimeMeetingComponent
      }
    ]
  },
  {
    path: feRoutes.routes.app.confirmEmail,
    component: EmailComfirmationComponent
  },
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
