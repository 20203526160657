import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {AuthenticationComponent} from "./authentication.component";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import {ForgetPasswordComponent} from "./forget-password/forget-password.component";
import {SuccessResetComponent} from "./success-reset/success-reset.component";
import {SuccessPasswordChangeComponent} from "./success-password-change/success-password-change.component";
import {default as feRoutes} from "src/app/utils/constants/fe-routes.json";

const routes: Routes = [
  {
    path: feRoutes.routes.app.authenticationModule.children.path,
    pathMatch: 'full',
    redirectTo: feRoutes.routes.app.authenticationModule.children.login.path
  },
  {
    path: feRoutes.routes.app.authenticationModule.children.path,
    component: AuthenticationComponent,
    children: [
      {
        path: feRoutes.routes.app.authenticationModule.children.login.path,
        component: LoginComponent,
        title: feRoutes.routes.app.authenticationModule.children.login.title,
      },
      {
        path: feRoutes.routes.app.authenticationModule.children.forgetPassword.path,
        component: ForgetPasswordComponent
      },
      {
        path: feRoutes.routes.app.authenticationModule.children.successReset.path,
        component: SuccessResetComponent
      },
      {
        path: feRoutes.routes.app.authenticationModule.children.resetPassword.path,
        component: ResetPasswordComponent
      },
      {
        path: feRoutes.routes.app.authenticationModule.children.successPasswordChange.path,
        component: SuccessPasswordChangeComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {
}
