<section class="reset-password__page" style="background-image: url('./assets/images/reset-password.png')">
  <div class="container">
    <form [formGroup]="updatePasswordOnResetRequestForm" (ngSubmit)="updatePasswordRequestAfterResetRequest()">
      <div class="section-title text-start">
        <h3>{{ 'loginPage.changePassword' | translate}}</h3>
        <p>{{ 'loginPage.enterNewDetails' | translate}}</p>
      </div>
      <div class="form-group">
        <label for="newPassword"> {{ 'loginPage.newPassword' | translate}}</label>
        <div class="input-wrapper">
          <input type="password" class="form-control"
                 required
                 name="newPassword"
                 formControlName="newPassword"
                 id="newPassword"
                 autocomplete="on"
                 placeholder="{{ 'loginPage.password' | translate}}">
          <button
            type="button"
            class="show-password flex-center">
            <i showHidePassword class="far fa-eye"></i>
          </button>
        </div>
        <ng-container *ngFor="let validation of updatePasswordOnResetRequestFormValidationMessages.newPassword">
          <div class="invalid-feedback"
               [style.display]="shouldShowError(updatePasswordOnResetRequestForm.get('newPassword'), validation)">
            {{validation.message | translate }}
          </div>
        </ng-container>
      </div>
      <div class="form-group">
        <label for="confirmNewPassword">{{ 'loginPage.confirmNewPassword' | translate}}</label>
        <div class="input-wrapper">
          <input type="password" class="form-control"
                 required
                 name="confirmNewPassword"
                 formControlName="confirmNewPassword"
                 id="confirmNewPassword"
                 autocomplete="on"
                 placeholder="{{ 'loginPage.password' | translate}}">
          <button
            type="button"
            class="show-password flex-center">
            <i showHidePassword class="far fa-eye"></i>
          </button>
        </div>
        <ng-container *ngFor="let validation of updatePasswordOnResetRequestFormValidationMessages.confirmNewPassword">
          <div class="invalid-feedback"
               [style.display]="shouldShowError(updatePasswordOnResetRequestForm.get('confirmNewPassword'), validation)">
            {{validation.message | translate }}
          </div>
        </ng-container>
      </div>

      <div *ngIf="errorMsg" class="alert alert-danger mb-3 mt-3 d-flex justify-content-between" role="alert">
        {{errorMsg | translate}}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>

      <div class="row reset-actions">
        <div class="col-md-6">
          <div class="action">
            <button type="submit" class="button filled"
                    [disabled]="updatePasswordOnResetRequestForm.invalid" (click)="checkValidations(updatePasswordOnResetRequestForm)">
              <i *ngIf="isSubmitting" class="fa fa-spinner fa-spin"></i>{{submitButtonText | translate}}
            </button>
          </div>
        </div>

        <div class="col-md-6">
          <a [routerLink]="[routes.login]" class="anchor white-secondary">{{'cancel' | translate}}</a>
        </div>
      </div>

    </form>
  </div>
</section>
