import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {default as apiLinks} from "src/app/utils/constants/api-links.json";
import {Observable} from "rxjs";
import {PaginationApiResponse} from "../pagination-api-response.model";
import {CompanyShareholderDetailModel} from "./company-shareholder-detail.model";
import {ApiResponse} from "../api-response.model";
import {CompanyShareOffersModel} from "../lookup/models/company-share-offers.model";
import {SearchCriteriaModel} from "../shared/search-criteria.model";

@Injectable({
  providedIn: 'root'
})
export class ShareholdingsService {

  constructor(
    private apiService: ApiService,
  ) {
  }

  /**
   * Get user shareholding companies of shareholder.
   */
  public getAllUserShareholdingCompanies(): Observable<any> {
    return this.apiService.get(apiLinks.api.mainService.shareholdings.getUserShareholdingCompaniesLookup);
  }

  /**
   * generate offer shares request.
   */
  public saveOrUpdateOfferShares(companyShareOffersModel: CompanyShareOffersModel): Observable<any> {
    return this.apiService.post(apiLinks.api.mainService.shareholdings.saveOrUpdateOfferRequest, companyShareOffersModel);
  }

  /**
   * generate bid shares request.
   */
  public saveCompanyBidShares(companySharesBid: any): Observable<any> {
    return this.apiService.post(apiLinks.api.mainService.shareholdings.saveCompanyBidShares, companySharesBid);
  }

  /**
   * generate offer shares request.
   */
  public getUsersShareholding(pageSize: number, pageNumber: number): Observable<ApiResponse<PaginationApiResponse<CompanyShareholderDetailModel>>> {
    return this.apiService.getT<ApiResponse<PaginationApiResponse<CompanyShareholderDetailModel>>>(apiLinks.api.mainService.shareholdings.getUsersShareholdings + pageNumber + '/' + pageSize);
  }

  /**
   * get Target buyers List.
   */
  public getTargetBuyersList(searchCriteria: any): Observable<any> {
    return this.apiService.get(apiLinks.api.mainService.shareholdings.getTargetBuyersList,searchCriteria);
  }

  /**
   * get User Summary list
   */
  public getUserOrderSummary(): Observable<any> {
    return this.apiService.get(apiLinks.api.mainService.shareholdings.getUserOrderSummary);
  }

  /**
   * get My orders list
   */
  public getMyOrders(pageSize: number, pageNumber: number): Observable<any> {
    return this.apiService.get(apiLinks.api.mainService.shareholdings.getMyOrders + pageSize + '/' + pageNumber );
  }
  /**
   * get Company Offers list
   */
  public getCompanyOffers(companyId: number): Observable<any> {
    return this.apiService.get(apiLinks.api.mainService.shareholdings.getCompanyOffers.replace('{companyId}',companyId.toString()) );
  }

  /**
   * get My Trades list
   */
  public getMyTrades(): Observable<any> {
    return this.apiService.get(apiLinks.api.mainService.shareholdings.getMyTrades );
  }

  /**
   * Cancel Share Offer Request
   */
  public cancelledSharesOfferRequest(sharesOfferId: number): Observable<any> {
    return this.apiService.put(apiLinks.api.mainService.shareholdings.cancelledSharesOfferRequest.replace('{sharesOfferId}', sharesOfferId.toString()) );
  }

  /**
   * Delete offer
   */
  public deleteOfferRequest(offerId: number): Observable<any> {
    return this.apiService.delete(apiLinks.api.mainService.shareholdings.deleteOfferRequest.replace("{offerId}", offerId.toString()) );
  }

  /**
   * Delete Bid
   */
  public deleteBidRequest(bidId: number): Observable<any> {
    return this.apiService.delete(apiLinks.api.mainService.shareholdings.deleteBidRequest.replace("{sharesBidId}", bidId.toString()) );
  }

  public rejectTrade(tradeId: number, processId: number): Observable<any> {
    return this.apiService.post(apiLinks.api.mainService.shareholdings.rejectTrade + tradeId +'/'+ processId );
  }

  public acceptTrade(tradeId: number, processId: number, offeredSharesQuantity: number): Observable<any> {
    return this.apiService.post(apiLinks.api.mainService.shareholdings.acceptTrade + tradeId +'/'+ processId + '/' + offeredSharesQuantity );
  }

  public getSharesOfferBids(offerId: number, ): Observable<any> {
    return this.apiService.get(apiLinks.api.mainService.shareholdings.getSharesOfferBids.replace("{sharesOfferId}", offerId.toString()));
  }

  public getTradingCompanies(): Observable<any> {
    return this.apiService.get(apiLinks.api.mainService.shareholdings.getTradingCompanies);
  }

  public getAllActiveOffers(searchCriteria: SearchCriteriaModel): Observable<any> {
    return this.apiService.post(apiLinks.api.mainService.company.allActiveOffers, searchCriteria);
  }

  public shareNegotiateRequest(sharesNegotiationModel: any): Observable<any> {
    return this.apiService.post(apiLinks.api.mainService.shareholdings.shareNegotiateRequest,sharesNegotiationModel);
  }

  public getTradingSummaryOnSearchWithFilterData(searchCriteria: SearchCriteriaModel): Observable<any> {
    return this.apiService.post(apiLinks.api.mainService.shareholdings.tradingSummaryOnSearchWithFilterData, searchCriteria);
  }

  public checkIfUserIsShareholderOfCompany(companyId: number): Observable<any> {
    return this.apiService.get(apiLinks.api.mainService.shareholdings.checkIfUserIsShareholderOfCompany.replace("{companyId}", companyId.toString()));
  }

  public getCurrentNegotiatedValues(offerId: number): Observable<any> {
    return this.apiService.get(apiLinks.api.mainService.shareholdings.getCurrentNegotiatedValues.replace("{offerId}", offerId.toString()));
  }

  public checkIfUserHasPendingOfferOfCompany(offerId: number): Observable<any> {
    return this.apiService.get(apiLinks.api.mainService.shareholdings.checkIfUserHasPendingOfferOfCompany.replace("{companyId}", offerId.toString()));
  }

}
